import React, { Component } from "react";
import Header from "./Header";
import Map from "./Map";
import Checklist from "./Checklist";
import BCS from "./data/bcs.json";
import "./App.css";

export default class App extends Component {
  constructor(props) {
    super(props);

    // 원본 데이터에서 마스터 기초 데이터 만들기
    const arrTypeForCheck = [];
    const types = [];

    const arrRegionForCheck = [];
    const regions = [];

    BCS.forEach(each_bcs => {
      if (arrTypeForCheck.indexOf(each_bcs["TYPE_CODE"]) < 0) {
        arrTypeForCheck.push(each_bcs["TYPE_CODE"]);
        types.push({
          id: each_bcs["TYPE_CODE"].trim(),
          name: each_bcs["TYPE_NAME"],
          isChecked: false
        });
      }

      if (arrRegionForCheck.indexOf(each_bcs["REGION"]) < 0) {
        arrRegionForCheck.push(each_bcs["REGION"]);
        regions.push({
          id: each_bcs["REGION"].trim(),
          name: each_bcs["REGION"],
          isChecked: false
        });
      }
    });

    this.state = {
      item_types: types,
      regions: regions,
      items: [...BCS],
      checkedAllRegion: false,
      checkedAllType: false,
      currentItem: ""
    };
  }

  componentDidMount = () => {};

  toggleCheckedTypes = id => {
    const { item_types } = this.state;

    this.setState({
      item_types: item_types.map(each_type =>
        id === each_type.id
          ? { ...each_type, isChecked: !each_type.isChecked }
          : each_type
      )
    });
  };

  toggleCheckedRegions = id => {
    const { regions } = this.state;

    this.setState(() => {
      return {
        regions: regions.map(region =>
          id === region.id
            ? { ...region, isChecked: !region.isChecked }
            : region
        )
      };
    });
  };

  handleCheckedAllType = () => {
    const { item_types, checkedAllType } = this.state;
    const new_item_types = item_types.map(item => {
      return { ...item, isChecked: !checkedAllType };
    });

    this.setState(prevState => {
      return {
        item_types: new_item_types,
        checkedAllType: !prevState.checkedAllType
      };
    });
  };

  handleCheckedAllRegion = () => {
    const { regions, checkedAllRegion } = this.state;
    const new_regions = regions.map(region => {
      return { ...region, isChecked: !checkedAllRegion };
    });

    this.setState(prevState => {
      return {
        regions: new_regions,
        checkedAllRegion: !prevState.checkedAllRegion
      };
    });
  };

  handleUpdateCurrentItem = (title, addr) => {
    this.setState(() => {
      return {
        currentItem: `${title}, ${addr}`
      };
    });
  };

  render() {
    const { item_types, regions, items, currentItem } = this.state;
    const enableTypes = [];
    const enableRegions = [];

    item_types.forEach(each_type => {
      if (each_type.isChecked === true) {
        enableTypes.push(each_type.id);
      }
    });

    regions.forEach(region => {
      if (region.isChecked === true) {
        enableRegions.push(region.name);
      }
    });

    const filteredItemsByType = items.filter(
      item => enableTypes.indexOf(item.TYPE_CODE) > -1
    );

    const filteredItemsByRegion = filteredItemsByType.filter(
      item => enableRegions.indexOf(item.REGION) > -1
    );

    // console.log(enableItems);

    return (
      <div>
        <div>
          <Header currentItem={currentItem}>부천시 사회복지시설 지도</Header>
        </div>
        <div className="mr-md-4">
          <div className="row">
            <div className="col-md-8">
              <Map
                spots={filteredItemsByRegion}
                handleUpdateCurrentItem={this.handleUpdateCurrentItem}
              />
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-6">
                  <button
                    className="btn btn-outline-primary btn-block my-3"
                    onClick={this.handleCheckedAllType}
                  >
                    유형 전체선택/해제
                  </button>
                  <Checklist
                    list={item_types}
                    toggleChecked={this.toggleCheckedTypes}
                  />
                </div>
                <div className="col-md-6">
                  <button
                    className="btn btn-outline-success btn-block my-3"
                    onClick={this.handleCheckedAllRegion}
                  >
                    동 전체선택/해제
                  </button>
                  <Checklist
                    list={regions}
                    toggleChecked={this.toggleCheckedRegions}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <div className="alert alert-light text-center">
                    총 {filteredItemsByRegion.length}개의 기관이 선택되어
                    있습니다.
                  </div>
                  <div className="alert alert-light">
                    <ul>
                      {filteredItemsByRegion &&
                        filteredItemsByRegion.map(region => (
                          <li key={region.ID} className="pb-2">
                            <h5>
                              <span className="badge badge-secondary">
                                {region.NAME}
                              </span>
                            </h5>

                            <small className="text-muted">{region.ADDR}</small>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
