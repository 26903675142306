import React from "react";
import GoogleMapReact from "google-map-react";
import "./Map.css";
import Pin from "./data/gpin.png";

const Marker = ({ title, addr, handleUpdateCurrentItem }) => {
  return (
    <div
      className="icon-container"
      onClick={() => handleUpdateCurrentItem(title, addr)}
    >
      <span className="icon">
        <img className="icon" src={Pin} alt={title} />
      </span>
    </div>
  );
};

const Config = {
  center: {
    lat: 37.503436,
    lng: 126.76608
  },
  zoom: 14
};

const Map = ({ spots, handleUpdateCurrentItem }) => {
  return (
    <div className="map-container">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyCUCjRImI_k5ps1fg6dEVntX5dmYRpcG20"
        }}
        defaultCenter={Config.center}
        defaultZoom={Config.zoom}
      >
        {spots &&
          spots.map(spot => (
            <Marker
              key={spot.ID}
              lat={spot.LAT}
              lng={spot.LNG}
              title={spot.NAME}
              addr={spot.ADDR}
              handleUpdateCurrentItem={handleUpdateCurrentItem}
            />
          ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
