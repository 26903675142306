import React, { Component } from "react";
import "./Checkbox.css";

export default class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: false
    };
  }

  toggleCheck = () => {
    const { id, toggleChecked } = this.props;
    const { isChecked } = this.state;

    this.setState({
      isChecked: !isChecked
    });

    toggleChecked(id);
  };

  render() {
    const { children, id, name, count, isChecked } = this.props;

    return (
      <li
        key={id}
        className="list-group-item d-flex justify-content-between align-items-center"
        style={{ minWidth: "190px" }}
        onClick={this.toggleCheck}
      >
        <div className="checkbox">
          <label htmlFor={id}>
            <input
              type="checkbox"
              id={id}
              name={name}
              checked={isChecked}
              onChange={this.toggleCheck}
            />
          </label>
          <span className="ch-children">{children}</span>
        </div>
        {count && <span className="badge badge-light ch-badge">{count}</span>}
      </li>
    );
  }
}
