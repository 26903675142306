import React from "react";
import Checkbox from "./Checkbox";

const Checklist = ({ list, toggleChecked }) => {
  if (!list) {
    return <div>(no data)</div>;
  }

  return (
    <ul className="list-group list-group-flush">
      {list.map(item => (
        <Checkbox
          key={item.id}
          id={item.id}
          name={item.name}
          isChecked={item.isChecked}
          count={item.count}
          toggleChecked={toggleChecked}
        >
          {item.name}
        </Checkbox>
      ))}
    </ul>
  );
};

export default Checklist;
