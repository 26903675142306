import React from "react";
import "./Header.css";

var timer = undefined;

const Header = ({ children, currentItem }) => {
  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(() => {
    document.getElementById("clicked-item-info").innerText = "";
  }, 10000);

  return (
    <div className="header-container">
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <a className="navbar-brand" href="/">
          {children}
        </a>
        <small id="clicked-item-info" className="text-light">
          {currentItem}
        </small>
      </nav>
    </div>
  );
};

export default Header;

// setTimeout(() => $('#clicked-item-info').text = '', 5000);
